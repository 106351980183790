<template>
  <div class="gf-sidebar font-bold p-0.5">
    <!-- Numbered Page Nav -->
    <div v-if="isNumbered" class="w-full flex flex-row justify-center items-center">
        <span>Pages:</span>
        <div v-for="i in pages" :key="i" class="flex flex-row items-center">
          <div :class="`${i === page ? '' : 'cursor-pointer underline'} px-1`"
            @click="selectPage(i)">{{i}}</div>
          <span v-if="i < pages">|</span>          
        </div>
    </div>
    <!-- Top Page Nav -->
    <div v-else class="w-full flex flex-row justify-center items-center italic">
      <div v-if="page > 1" @click="selectPage(1)" class="mx-1 underline cursor-pointer">First Page</div>
      <div v-if="page > 1">|</div>
      <div v-if="page > 2" @click="selectPage(parseInt(page)-1)" class="mx-1 underline cursor-pointer">Prev Page</div>
      <div v-if="page > 2" >|</div>    
      <div class="mx-1">Page {{page}} of {{pages}}</div>
      <div v-if="page < pages -1">|</div>
      <div v-if="page < pages -1"  @click="selectPage(parseInt(page)+1)" class="mx-1 underline cursor-pointer">Next Page</div>
      <div v-if="page < pages">|</div>
      <div v-if="page < pages"  @click="selectPage(pages)" class="mx-1 underline cursor-pointer">Last Page</div>    
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBar',
  props: {
    isNumbered: Boolean,
    pages: Number,
    page: Number
  },
  methods: {
    selectPage(page){
      console.log('pageBar.selectPage: ', page)
      this.$emit('selectPage', page);
    },
  }
}
</script>
