<template>
  <div class="w-full flex flex-col gap-y-0.5">
    <PageBar :pages="pages" :page="page" @selectPage="selectPage" />
    <!-- Topic List Header -->
    <div class="w-full flex flex-row items-center text-center font-bold italic gap-x-0.5">
      <div class="gf-content-header p-0.5 flex-grow">Topic</div>
      <div class="gf-content-header p-0.5 w-60">Created By</div>
      <div class="gf-content-header p-0.5 w-24">Msgs</div>
      <div class="gf-content-header w-40 p-0.5">Last Post</div>
    </div>
    <!-- Topics -->
    <div v-for="topic in topics" :key="topic.id"
      class="w-full flex flex-row gap-x-0.5">
      <div @click="selectTopic(topic.topicId)" class="gf-content-link px-0.5 flex-grow underline cursor-pointer">{{topic.title}}</div>
      <div @click="this.$router.push(`/b${boardId}/u${topic.userName}`)" class="gf-content-link underline cursor-pointer px-0.5 w-60">{{topic.userName}}</div>
      <div class="gf-content px-0.5 w-24">{{topic.messageCount}}</div>
      <div class="gf-content px-0.5 w-40">{{formatDateTime(topic.lastPost)}}</div>
    </div>
    <PageBar :pages="pages" :page="page" :isNumbered="true" @selectPage="selectPage" />
  </div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'TopicList',
  components: {
    ControlBar,
    PageBar
  },
  props: {
    boardId: Number
  },
  data(){
    return {
      topics: [],
      boardInfo: []
    }
  },
  async mounted(){
    this.initialize();
  },
  methods:{
    initialize(){
      
      // Set params from route
      if (this.$route.params.topics) {
        this.topics = this.$route.params.topics;
      }
      if (this.$route.params.boardInfo) {
        this.boardInfo = this.$route.params.boardInfo;
      }

      // Set screen title and type
      this.$store.state.screenTitle = this.$store.state.boardTitle
      document.title = this.$store.state.screenTitle

      this.$store.state.screenType = 'board'
    },
    formatDateTime(dateStr) {
      const date = new Date(dateStr);
      const month = date.getMonth() + 1; 
      const day = date.getDate();
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      hours = hours % 12;
      hours = hours ? hours : 12; 
      const minutesPadded = minutes < 10 ? '0' + minutes : minutes;

      return `${month}/${day} ${hours}:${minutesPadded}${ampm}`;
    },
    getBoardInfo(){
        return new Promise(resolve => {
            fetch(`${this.host}/boards/boards.php?boardId=${this.$props.boardId}`, {
                method: 'GET'
                })
                .then(res => res.json())
                .then(data => { resolve(data); })
                .catch(error => { resolve(error); })  
        });
    },
    getTopics(){
        return new Promise(resolve => {
            fetch(`${this.host}/topics/topics.php?boardId=${this.$props.boardId}`, {
                method: 'GET'
                })
                .then(res => res.json())
                .then(data => { resolve(data); })
                .catch(error => { resolve(error); })  
        });
    },
    selectPage(page){
      console.log('topicLost.selectPage, ', page)
      this.page = page;
    },
    selectTopic(topicId){
      this.$router.push(`/b${this.boardId}/t${topicId}`);
    }
  },
  setup(){

    let page = ref(1);
    let pages = ref(1);
    let topics = ref([]);

    return {
      page,
      pages,
      topics

    }
  }
}
</script>
