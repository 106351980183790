<template>
  <!--Post Form-->
  <div v-if="mode == 'post'" class="w-full flex flex-col gap-y-0.5">
    <div class="gf-content-header p-0.5 block text-center font-bold" for="topicTitle">{{!topicId ? 'Create New Topic' : 'Post Reply'}}</div>
    <div class="w-full gf-notice p-0.5 text-center" v-if="error">{{error}}</div>
    <div v-if="!topicId" class="flex flex-col gap-y-0.5">
      <label class="gf-content-header p-0.5 block" for="topicTitle">Topic Title</label>
      <div class="gf-content p-0.5">
        <input v-if="!topicId" class="w-full"
          id="topicTitle" v-model="topicTitle" />
      </div>
    </div>
      <label class="gf-content-header p-0.5" for="message">Message</label>
      <div class="gf-content p-0.5">
        <textarea v-model="message" class="w-full h-48" id="message" />
      </div>
    
    <!-- Buttons -->
    <div class="w-full flex flex-row justify-center gf-content p-0.5 gap-x-0.5">
      <div @click="preview" class="p-2 gf-button rounded-sm">Preview</div>
      <div @click="post()" class="p-2 gf-button rounded-sm">Post Message</div>
    </div>    
  </div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'Post',
  components: {
    ControlBar,
    PageBar
  },
  props: {
    boardId: Number,
    topicId: Number
  },
  async mounted(){
    if(this.$props.topicId)
      this.$store.state.screenType = 'postReply';
    else
      this.$store.state.screenType = 'postTopic';

    await this.updatePostToInfo();
  },
  methods:{
    // Get info about the board or topic being posted to
    async updatePostToInfo(){
      this.postToInfo = await this.updatePostToInfoRequest();
      // Set screen title to board or topic title
      if(this.postToInfo.topicTitle){
        this.$store.state.screenTitle = this.postToInfo.topicTitle;
      }else if(this.postToInfo.boardTitle){
        this.$store.state.screenTitle = this.postToInfo.boardTitle;
      }
      // Check for user signature
      /*
      if(this.postToInfo.signature && this.postToInfo.signature.length > 0){
        this.message = ` 
---
${this.postToInfo.signature}`;
      }*/
    },
    updatePostToInfoRequest(){
        return new Promise(resolve => {
            fetch(`${this.host}/users/post.php`, {
                method: 'POST', 
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ mode: 'getInfo', token: localStorage.getItem('token'), boardId: this.$props.boardId, topicId: this.$props.topicId} )
                })
                .then(res => res.json())
                .then(data => { resolve(data); })
                .catch(error => { resolve(error); })  
        });
    },
    async post(){
      await this.postRequest();
    },
    postRequest(){
      let url = `${this.host}/users/post.php`
      let data = 
      {
        message: this.message,
        topicTitle: this.topicTitle,
        boardId: this.$props.boardId,
        topicId: this.$props.topicId,
        token: localStorage.getItem('token'),
        mode: 'post'
      }
      let request = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }

      // Send the request to the server
      fetch(url, request)
        .then(response => response.json()) // Parsing the JSON response
        .then(data => {
            if (data.success) {
              // Go to topic after posted
              this.$router.push(`/b${data.boardId}/t${data.topicId}`)
            } else {
              this.error = data.message
            }
        })
        .catch(error => {
              this.error = error
        });
    }
  },
  setup(){

    const board = ref({})
    const topic = ref({})
    const topicTitle = ref('');
    const message = ref('');
    const showPreview = ref(false);
    const postToInfo = ref({});
    const mode = ref('post')
    const error = ref('')

  return {
      board,
      error,
      message,
      mode,
      postToInfo,
      showPreview,
      topicTitle
    }
  }
}
</script>
