<template>
  <div class="flex flex-row items-center justify-between text-sm gf-header">
    <!--Path-->
    <div class="p-1 flex flex-row items-center gap-x-1 flex-grow self-stretch">
      <div @click="this.$router.push('/')" :class="`${this.$store.state.screenType == 'home' ? 'font-bold' : ''} cursor-pointer`">ProjectB8</div>
      <div v-if="this.$store.state.screenType != 'home'">/</div> 
      <div v-if="this.$store.state.screenType != 'home'" @click="this.$router.push('/b')"
        :class="`${this.$store.state.boardId ? '' : 'font-bold'} cursor-pointer`">Boards</div> 
      <!-- Board -->   
      <div v-if="this.$store.state.boardId">/</div> 
      <div v-if="this.$store.state.boardId" @click="this.$router.push(`/b${this.$store.state.boardId}`)"
        :class="`${!this.$store.state.topicId ? 'font-bold' : ''} cursor-pointer`">{{this.$store.state.boardTitle}}</div>
      <!-- Topic -->
      <div v-if="this.$store.state.topicId">/</div> 
      <div v-if="this.$store.state.topicId" @click="this.$router.push(`/b${this.$store.state.boardId}/t${this.$store.state.topicId}`)"
        class="font-bold cursor-pointer">{{this.$store.state.topicTitle}}</div>
    </div>
    <!--User Controls-->
    <div class="p-1 flex flex-row items-center justify-end gap-x-1 flex-grow self-stretch">
      <div v-for="(option, i) in userOptions" :key="option.emit" class="flex flex-row">
        <div class="cursor-pointer font-bold"  @click="this.$emit(option.emit)">{{option.label}}</div>
        <span v-if="i < userOptions.length - 1" class="mx-1">|</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Path',
  props: {
    msg: String
  },
  computed: {
    
    userOptions(){
      let options = [];
      
      // User, login, and register
      if(this.$store.state.isLoggedIn){
        options.push({ label: `${this.$store.state.user.userName} (${this.$store.state.user.userLevel})`, emit: 'profile' });
        //options.push({ label: `Messages (2)`, emit: 'pms' });
        //options.push({ label: `Notifications`, emit: 'notifications' });
        options.push({ label: `Logout`, emit: 'logout' });
      }else{
        options.push({ label: `Login`, emit: 'login' });
        options.push({ label: `Register`, emit: 'register' });
      }

      return options;
    }

  },
  methods: {
    optionSelected(option){
      this.$router.push(option.to)
    }
  }
}
</script>
