<template>
  <div class="gf-header flex flex-row w-full p-0.5 justify-center">
    <div v-for="(option, i) in options" :key="option.to" class="flex flex-row items-center font-bold underline">
      <div class="px-2 cursor-pointer" @click="this.$emit(option.emit)">{{option.label}}</div>
      <span v-if="i !== options.length -1">|</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ControlBar',
  props: {
    options: Object
  },
  methods: {
    optionSelected(option){
      this.$router.push(option.to)
    }
  }
}
</script>

