<template>
  <div class="gf-sidebar flex flex-col">
    <span class="font-bold w-full text-center text-xl mb-2">PROJECT B8</span>
    <div v-for="option in [
      { label: 'Home', to: '/' },
      { label: 'Boards', to: '/b' },
      { label: 'Board8', to: '/b8' },
      { label: 'Feature Requests, Bug Reports', to: '/b2' }
      /*
      { label: 'Project Announcements', to: '/b6' }
      { label: 'ASCII Library', to: '/ascii' },
      { label: 'Mafia Games', to: '/mafia' },*/
    ]" :key="option.to"
      class="cursor-pointer font-bold px-1 leading-4 py-1" @click="optionSelected(option)">
      {{option.label}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    async optionSelected(option){
      await this.$router.push(option.to)
      //this.$router.go(0)
    }
  }
}
</script>
