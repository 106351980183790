<template>
  <div class="w-full text-center gf-content">
    <p class="font-bold">Welcome to Project B8 / b8.wtf / whatever it ends up being called.</p>
    <p>The goal of this project is to create a modern version of the classic GameFAQs style forum to serve as a backup in case the actual board ever closes.</p>
  </div>
  <div class="w-full text-center gf-header font-bold flex flex-row p-0.5 gap-x-2 justify-center">
    <span>Basic Feature Progress</span>
    <span>|</span>
    <div @click="this.$router.push('/b2')" class="underline font-bold cursor-pointer">Make Feature Requests</div>
    </div>
  <div class="w-full flex flex-row gap-x-0.5">
    <div class="w-1/2 flex flex-col gap-y-0.5">
    <div class="w-full gf-message-header text-center">Done</div>
      <div v-for="e in done" :key="e" class="gf-content p-0.5 line-through hover:no-underline">{{e}}</div>
    </div>
    <div class="w-1/2 flex flex-col gap-y-0.5">
    <div class="w-full gf-message-header text-center">ToDo</div>
      <div v-for="e in toDo" :key="e" class="gf-content p-0.5">{{e}}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Home',
  components: {
  },
  mounted(){    
    // Set global store information
    this.$store.state.screenTitle = 'Project B8';
    this.$store.state.screenType = 'home';    
    this.$store.state.boardId = null
    this.$store.state.boardTitle = null
    this.$store.state.topicId = null
  },
  setup(){

    const toDo = ref(['PMs', 'Tracking Topics', 'Middle Click Links', 'Preview/Edit Messages', 'Link/Image/Embedded Video Posting', 'Themes/Dark Mode', 'User Pinging', 'Topic Polls', 'Mobile View', 'Mafia Bots', 'Jump to Unread', 'Sticky Topics']);
    const done = ref(['Registration/Login', 'Posting', 'Basic API Setup', 'Basic Text Formatting (b, i, s, u)', 'User Profiles', 'Signatures', 'Smoother Screen Loading', 'Recent Active Topics']);

    return {
      done,
      toDo

    }
  }
}
</script>
