<template>
  <div class="flex flex-col gap-y-0.5">
    <!-- Header -->
    <div class="gf-content-header p-0.5 font-bold">{{title}}</div>
    <div v-if="error" class="gf-error p-0.5 font-bold">{{error}}</div>
    <!-- Fields -->
    <div v-for="field in fields" :key="field.id" 
      class="flex flex-row gf-content p-0.5 justify-center items-start">
      <label :for="field.id" class="w-32">{{field.label}}</label>
      <input :type="field.type ? field.type : 'text'" class="w-48" :id="field.id" v-model="inputValues[field.id]" />
    </div>
    <!-- Buttons -->
    <div class="w-full flex flex-row justify-center gf-content p-0.5">
      <div @click="this.$emit('submit', inputValues)" class="p-2 gf-button rounded-sm">{{submitText}}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'Form',
  props: {
    error: String,
    fields: Object,
    input: Object,
    submitText: {
      type: String,
      default: 'Submit'
    },
    title: String

  },
  methods: {
  },
  mounted(){
    
    // Create input refs
    let inputs = {};
    for(var ii = 0; ii < this.$props.fields.length; ii++){
      let field = this.$props.fields[ii]
      inputs[field.id] = new ref('')
    }
    this.inputValues = inputs;

  },
  setup(){
    const inputValues = ref({})
    return {
      inputValues
    }
  }
}
</script>
