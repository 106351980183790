<template>
  <div class="w-full flex flex-col gap-y-0.5">
    <div v-if="registered" class="gf-notice text-center">
      Your account has been registerd, you can now <a class="underline cursor-pointer" @click="this.$router.push('/login')" href="#">login</a>
    </div>
    <Form v-else-if="!this.$store.state.isLoggedIn" title="User Login" 
      :error="error"
      :fields="[
        { id: 'username', label: 'Username' }, 
        { id: 'email', label: 'Email' }, 
        { id: 'password', label: 'Password', type: 'password' },
        { id: 'password2', label: 'Confirm Password', type: 'password' }]"
      submitText="Register"
      @submit="register" />
    <div v-else class="gf-content text-center" >You are logged in as <span class="font-bold">{{this.$store.state.user.userName}}</span></div>
  </div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import Form from '@/components/Form.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'Post',
  components: {
    ControlBar,
    Form,
    PageBar
  },
  props: {
    boardId: Number
  },
  async mounted(){
    this.initialize();
  },
  methods:{
    initialize(){
      // Set params from route
      if (this.$route.params.boards) {
        this.boards = this.$route.params.boards;
      }
      if (this.$route.params.boardCategories) {
        this.boardCategories = this.$route.params.boardCategories;
      }

      // Set global store information
      this.$store.state.screenTitle = 'Register Account'
      this.$store.state.screenType = 'register'
      this.$store.state.boardId = null
      this.$store.state.boardTitle = null
      this.$store.state.topicId = null
    },
    async register(data){
      
      // Make sure passwords match
      if(data.password != data.password2){
        this.error = 'Passwords must match'
        return;
      }

      await this.registerRequest(data);
    },
    registerRequest(data){
      this.error = '';
      let url = `${this.host}/users/register.php`
      let body = 
      {
        email: data.email,
        username: data.username,
        password: data.password
      }
      let request = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }

      // Sending the request to the server
      fetch(url, request)
        .then(response => response.json()) // Parsing the JSON response
        .then(data => {
            if (data.success) {
                this.registered = true
                console.log('User registered successfully:', data);
            } else {
                console.log('Registration failed:', data.message);
                this.error = data.message;
            }
        })
        .catch(error => {
            console.error('Error registering user:', error);
                this.error = error;
        });
    }
  },
  setup(){

    const error = ref('')
    const email = ref('email@email.com');
    const username = ref('uname');
    const password = ref('pass123');
    const passwordConfirm = ref('pass123');
    const registered = ref(false)

  return {
    email,
    error,
    password,
    passwordConfirm,
    username,
    registered
    }
  }
}
</script>
