import { createRouter, createWebHistory } from 'vue-router'

import BoardList from '../views/BoardList.vue'
import Board from '../views/Board.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import MessageDetail from '../views/MessageDetail.vue'
import Post from '../views/Post.vue'
import Register from '../views/Register.vue'
import Topic from '../views/Topic.vue'
import User from '../views/User.vue'
import store from '../store'; // Adjust the path according to your project structure


// Fetch user, board, topic, message data required for route
function dynamicFetch(to, from, next){
  console.log('dynamicFetch: ', to);
  let host = 'https://db.boardeight.com';

  let fetches = [];
  let fetchTypes = [];

  // Determine which ID to use in fetch urls
  let boardIdUse = to.params.boardId ? to.params.boardId : to.params.boardIdLite
  let topicIdUse = to.params.topicId ? to.params.topicId : to.params.topicIdLite

  // Check the route to determine what to load and set relevant state
  // Load board info
  if(to.params.boardId){
    fetches.push(fetch(`${host}/topics/topics.php?boardId=${to.params.boardId}`).then(r => r.json()))
    fetchTypes.push('topics')

    fetches.push(fetch(`${host}/boards/boards.php?boardId=${to.params.boardId}`).then(r => r.json()))
    fetchTypes.push('boardInfo')
  }
  // Check for just setting board ID without fetching full info
  // Still fetch board name
  else if(to.params.boardIdLite){    
    fetches.push(fetch(`${host}/boards/boards.php?boardId=${to.params.boardIdLite}`).then(r => r.json()))
    fetchTypes.push('boardInfo')
  }



  // Load user info
  let routeSplit = to.fullPath.split('/');
  let userSelf = routeSplit.indexOf('u') != -1;
  if(to.params.userName || userSelf){    
    let uname = userSelf ? store.state.user.userName :to.params.userName       
    let postDataUser = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify({ userName: uname,
          token: localStorage.getItem('token') })
      }
    fetches.push(fetch(`${host}/users/users.php`, postDataUser).then(r => r.json()));
    fetchTypes.push('userInfo')
  }



  // Load topic info
  if(to.params.topicId){
    fetches.push(fetch(`${host}/topics/topics.php?boardId=${boardIdUse}&topicId=${topicIdUse}&perPage=${store.state.messagesPerPage}${to.params.page ? '&page=' + to.params.page : '&page=1' }`).then(r => r.json()))
    fetchTypes.push('topicInfo')
  }
  // Check for just setting topic ID without fetching full info
  // Still fetch topic title
  else if(to.params.topicIdLite){
    fetches.push(fetch(`${host}/topics/topics.php?boardId=${boardIdUse}&topicId=${topicIdUse}&perPage=${store.state.messagesPerPage}${to.params.page ? '&page=' + to.params.page : '&page=1' }`).then(r => r.json()))
    fetchTypes.push('topicInfo')
  }


  // Load message detail info
  if(to.params.messageId){
    fetches.push(fetch(`${host}/messages/messages.php?boardId=${boardIdUse}&topicId=${topicIdUse}&messageId=${to.params.messageId}`).then(r => r.json()))
    fetchTypes.push('messageInfo')
  }

  // Use Promise.all to handle all fetches
  Promise.all(fetches)
    .then(data => {
      console.log('data: ', data);
      
      // Clear screen state values
      let boardId = null,
      boardTitle = null,
      screenTitle = null,
      topicId = null,
      topicTitle = null
      
      // Go through all fetched data setting param 
      // and setting relevant store state
      for(var ii = 0; ii < fetchTypes.length; ii++){
        console.log('fetchType: ', fetchTypes[ii], data[ii])
        switch(fetchTypes[ii]){

          case 'boardInfo':
            boardId = data[ii].boardId
            boardTitle = data[ii].title
            break;
            
          case 'topicInfo':
            topicId = data[ii].topicId
            topicTitle = data[ii].topicTitle
            break;
            
        }
        to.params[fetchTypes[ii]] = data[ii];
      }

      // Update stored values
      store.state.boardId = boardId
      store.state.boardTitle = boardTitle
      store.state.screenTitle = screenTitle
      store.state.topicId = topicId
      store.state.topicTitle = topicTitle
      
      next(); // Proceed to the route
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      next(false); // Stop the routing or redirect if needed
    });
}

// /b - Fetch info for board list
function fetchBoards(to, from, next) {
  Promise.all([
    fetch(`${host}/boards/categories.php`),
    fetch(`${host}/boards/boards.php`),
    fetch(`${host}/topics/topics.php?recent=1`)
  ])
  .then(responses => {
    // Check if all responses are ok
    if (!responses.every(response => response.ok)) {
      throw new Error('One or more network responses were not ok');
    }
    // Parse all responses as JSON
    return Promise.all(responses.map(response => response.json()));
  })
  .then(data => {
    // Assuming data[0] is from boardCategories and data[1] is from boards
    to.params.boardCategories = data[0];
    to.params.boards = data[1];
    to.params.recentTopics = data[2];
    next();
  })
  .catch(error => {
    console.error('Error fetching data:', error);
    next(false); // Stop the routing or redirect if needed
  });
}


let host = 'https://db.boardeight.com';
const routes = [
  {
    path: '/-',  
    name: 'dummy',
    component: { template: '<div></div>' } // Minimal component
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/b',
    name: 'boards',
    component: BoardList,
    beforeEnter: fetchBoards
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/b:boardIdLite/t:topicIdLite/u',
    name: 'user_selfTopic',
    component: User,
    props: true,
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardIdLite/t:topicIdLite/u:userName',
    name: 'user_topic',
    component: User,
    props: true,
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardIdLite/t:topicIdLite/m:messageId',
    name: 'messageDetail',
    component: MessageDetail,
    props: true,
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardIdLite/u',
    name: 'user_selfBoard',
    component: User,
    props: true,
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardIdLite/u:userName',
    name: 'user_board',
    component: User,
    props: true,
    beforeEnter: dynamicFetch
  },
  {
    path: '/u',
    name: 'user_self',
    component: User,
    beforeEnter: dynamicFetch
  },
  {
    path: '/u:userName',
    name: 'user',
    component: User,
    props: true,
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardId',
    name: 'board',
    component: Board,
    props: true,
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardId/post',
    name: 'post_topic',
    component: Post,
    props: true
  },
  {
    path: '/b:boardIdLite/t:topicId',
    name: 'topic',
    component: Topic,
    props: true,  
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardIdLite/t:topicId/p:page',
    name: 'topic_page',
    component: Topic,
    props: true,  
    beforeEnter: dynamicFetch
  },
  {
    path: '/b:boardId/t:topicId/post',
    name: 'post_message',
    component: Post,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Check for reloading the same path with different params
// If so, call the appropriate beforeEnter method to trigger a screen update
router.beforeEach((to, from, next) => {
  
  if (to.name === from.name && to.fullPath !== from.fullPath) {
    dynamicFetch(to, from, next);/*
    let screen = to.name.split('_')[0]
    switch(screen){
      case 'board':
        dynamicFetch(to, from, next);
      break;

      case 'topic':
        fetchTopic(to, from, next);
      break;
      
      case 'user':
        fetchUser(to, from, next);
      break;
    }*/
  } else {
    next();
  }
});

export default router
