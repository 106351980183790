<template>
  <div class="w-full flex flex-col h-full" v-if="loaded">
    <div class="flex flex-col justify-start h-full">
      <SideBar v-if="this.$store.state.showSidebar" class="w-48 fixed h-full"/>  
      <!-- Path / User Bar -->
      <div :class="`${this.$store.state.showSidebar ? 'pl-48' : ''}
        ${this.$store.state.stickyHeader ? 'fixed' : ''} w-full`">
        <Path class="border-white border-l-2 border-t-2 border-b-2"
            @login="this.$router.push(`/login`)"
            @logout="logout"
            @register="this.$router.push(`/register`)"
            @profile="this.$router.push(`${$store.state.boardId ? '/b'+$store.state.boardId : ''}${$store.state.topicId ? '/t'+$store.state.topicId : ''}/u`)"
              />
      </div>
      <div :class="`${this.$store.state.showSidebar ? 'ml-48' : ''}
       ${this.$store.state.stickyHeader ? 'mt-8' : ''} flex flex-col gap-y-0.5`">
        <div class="flex flex-col pl-0.5 gap-y-0.5">
          <transition name="fade">
            <div :class="`text-4xl w-full text-center font-bold mt-2 h-10`">{{$store.state.screenTitle}}</div>
          </transition>
          <ControlBar
              v-if="controlBarOptions.length > 0"
              @login="this.$router.push(`/login`)"
              @logout="logout"
              @register="this.$router.push(`/register`)"
              @newTopic="this.$router.push(`/b${$store.state.boardId}/post`)"
              @newMessage="this.$router.push(`/b${$store.state.boardId}/t${$store.state.topicId}/post`)"
              @boardList="this.$router.push('/b')"
              @topicList="this.$router.push(`/b${$store.state.boardId}`)"
              @messageList="this.$router.push(`/b${$store.state.boardId}/t${$store.state.topicId}`)"
              @profile="this.$router.push(`${$store.state.boardId ? '/b'+$store.state.boardId : ''}${$store.state.topicId ? '/t'+$store.state.topicId : ''}/u`)"
              :options="controlBarOptions" />
          <router-view :key="$route.fullPath"
            @XupdateControlBar="updateControlBar++" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControlBar from '@/components/ControlBar.vue'
import Path from '@/components/Path.vue'
import SideBar from '@/components/SideBar.vue'
import TopBar from '@/components/TopBar.vue'

import { ref } from 'vue'

export default {
  components: {
    ControlBar,
    Path,
    SideBar,
    TopBar
  },
  async mounted(){
    await this.checkUserLogin();
  },
  computed: {
    controlBarOptions(){
      //this.updateControlBar++;
      let options = [];

      // User, login, and register
      /*
      if(this.$store.state.isLoggedIn){
        options.push({ label: `${this.$store.state.user.userName} (${this.$store.state.user.userLevel})`, emit: 'profile' });
      }else{
        options.push({ label: `Login`, emit: 'login' });
        options.push({ label: `Register`, emit: 'register' });
      }*/

      // Screen specific options
      switch(this.$store.state.screenType){

        case 'boardList':
          break;

        case 'board':
          options.push({ label: 'Board List', emit: 'boardList' });
          if(this.$store.state.isLoggedIn){
            options.push({ label: 'Create New Topic', emit: 'newTopic' });
            //options.push({ label: 'Add to Favorites', emit: 'fav' });
          }
          break;

        case 'login':
        case 'register':
        case 'home':
          options.push({ label: 'Board List', emit: 'boardList' });
          break;
          
        case 'postReply':      
          options.push({ label: 'Return to Topic List', emit: 'topicList' });    
          options.push({ label: 'Return to Message List', emit: 'messageList' });
          break;
          
        case 'postTopic':          
          options.push({ label: 'Return to Topic List', emit: 'topicList' });
          break;

        case 'profile':
          options.push({ label: 'Board List', emit: 'boardList' });
          if(this.$store.state.boardId)
            options.push({ label: 'Return to Topic List', emit: 'topicList' });    
          if(this.$store.state.topicId)
            options.push({ label: 'Return to Message List', emit: 'messageList' });
          break;

        case 'message':
          options.push({ label: 'Board List', emit: 'boardList' });
          options.push({ label: 'Return To Topic List', emit: 'topicList' });
          options.push({ label: 'Return To Message List', emit: 'messageList' });
          break;

        case 'topic':
          options.push({ label: 'Board List', emit: 'boardList' });
          options.push({ label: 'Topic List', emit: 'topicList' });
          if(this.$store.state.isLoggedIn){
            options.push({ label: 'Post New Message', emit: 'newMessage' });
            options.push({ label: 'Track Topic', emit: 'track' });
          }
          break;
      }   

      // Logout  
      //if(this.$store.state.isLoggedIn){
      //  options.push({ label: `Logout`, emit: 'logout' });
      //}

      return options;
    }
  },
  methods: {
    // Validates user auth token and fetches user's settings
    authCheck(){
      let url = `${this.host}/users/auth.php`
      let data = { token: localStorage.getItem('token') }
      let request = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(data)
      }

      // Send the request to the server
      fetch(url, request)
        .then(response => response.json()) // Parsing the JSON response
        .then(data => {
          console.log('authCheck.data: ', data);
            if (data.success) {
              // User user settings
              this.$store.state.messagesPerPage = data.settings.messagesPerPage
              this.$store.state.topicsPerPage = data.settings.topicsPerPage
              this.$store.state.stickyHeader = data.settings.stickyHeader
              this.$store.state.showSidebar = data.settings.showSidebar   
              
              this.loaded = true;           
            } 
            // Not a valid user, clear token and use default display settings
            else {
              this.error = data.message
              localStorage.setItem('user', {})
              this.loaded = true;
            }
        })
        // Not a valid user, clear token and use default display settings
        .catch(error => {
              this.error = error
              localStorage.setItem('user', {})
              this.loaded = true;
        });
      
    },
    async checkUserLogin(){

      await this.authCheck();
      

      // Check for logged in user info. This is just for display, requests use token to verify user
      let lsUser = {};
      try{
        lsUser = JSON.parse(localStorage.getItem('user'));
      } catch(e){}

      if(lsUser.userLevel){
        this.$store.state.user = lsUser;
        this.$store.state.isLoggedIn = true;
      }else{
        this.$store.state.isLoggedIn = false;
      }
    },
    logout(){
        this.$store.state.isLoggedIn = false;
        this.$store.state.user = {};
        localStorage.setItem('token', '');
        localStorage.setItem('user', {});
    },
  },
  setup(){    
    //const updateControlBar = ref(0)
    const loaded = ref(false)
    return{
      loaded
      //updateControlBar
    }

  }
}
</script>


