<template>
  <div class="w-full flex flex-col gap-y-0.5">    
    <!-- Board List Header -->
    <div class="w-full flex flex-row items-center text-center font-bold">
      <div class="gf-header mr-0.5 p-0.5 flex-grow">Board Title</div>
      <div class="gf-header mr-0.5 p-0.5 w-60">Topics</div>
      <div class="gf-header mr-0.5 p-0.5 w-24">Msgs</div>
      <div class="gf-header w-40 p-0.5">Last Post</div>
    </div>
    <!-- Uncategorized Boards -->
    <div v-for="board in uncategorizedBoards" @click="selectBoard(board.boardId)"
      :key="board.boardId" class="w-full flex flex-row items-center text-left leading-7">
      <div class="gf-content-link mr-0.5 p-0.5 flex-grow font-bold underline cursor-pointer text-lg">{{board.title}}</div>
      <div class="gf-content mr-0.5 p-0.5 w-60 self-stretch">{{board.topicCount}}</div>
      <div class="gf-content mr-0.5 p-0.5 w-24 self-stretch">{{board.messageCount}}</div>
      <div class="gf-content w-40 p-0.5 self-stretch">{{board.lastPost}}</div>
    </div>
    <!-- Categorized Boards -->
    <div v-for="category in categoriesToShow" 
      :key="category.categoryId" class="w-full flex flex-col gap-y-0.5">
      <div class="gf-content-header font-bold text-lg px-0.5">{{category.title}}</div>
      <!-- Category Boards -->
      <div v-for="board in categoryBoards(category.categoryId)" @click="selectBoard(board.boardId)"
         :key="board.boardId" class="w-full flex flex-row items-center text-left leading-7">
        <div class="gf-content-link mr-0.5 p-0.5 flex-grow font-bold underline cursor-pointer text-lg">{{board.title}}</div>
        <div class="gf-content mr-0.5 p-0.5 w-60 self-stretch">{{board.topicCount}}</div>
        <div class="gf-content mr-0.5 p-0.5 w-24 self-stretch">{{board.messageCount}}</div>
        <div class="gf-content w-40 p-0.5 self-stretch">{{board.lastPost}}</div>
      </div>
    </div>
    <!-- Recently Active Topics -->
    <div class="gf-content-header font-bold text-lg px-0.5">Recently Active Topics</div>
      <div v-for="topic in recentTopics" :key="topic.topicId" class="w-full flex flex-row items-center text-left leading-7">
        <div class="gf-content mr-0.5 p-0.5 flex-grow font-bold truncate">
          <a class="cursor-pointer underline" @click="this.$router.push(`/b${topic.boardId}/t${topic.topicId}`)">{{topic.title}}</a>,
          <a class="cursor-pointer underline text-xs" @click="this.$router.push(`/b${topic.boardId}`)">{{topic.boardTitle}}</a>
        </div>
        <div @click="this.$router.push(`/b${topic.boardId}/t${topic.topicId}/u${topic.lastPosterName}`)" class="gf-content-link mr-0.5 p-0.5 w-60 font-bold underline cursor-pointer truncate text-sm self-stretch leading-7">{{topic.lastPosterName}}</div> 
        <div class="gf-content mr-0.5 p-0.5 w-24 self-stretch">{{topic.messageCount}}</div>
        <div class="gf-content w-40 p-0.5 self-stretch">{{topic.lastPostTime}}</div>       
    </div>
  </div> 
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'BoardList',
  components: {
    ControlBar,
    PageBar
  },
  data(){
    return {
      boards: [],
      boardCategories: [],
      recentTopics: []
    }
  },
  async mounted(){
    this.initialize()
  },
  computed: {
    categoriesToShow(){
      let cats = [];
      for(var ii = 0; ii < this.boardCategories.length; ii++){
        if(true || this.hasAnyBoards(this.boardCategories[ii].categoryId))
          cats.push(this.boardCategories[ii])
      }
      return cats;
    },
    uncategorizedBoards(){
      let boards = [];
      for(var ii = 0; ii < this.boards.length; ii++){
        if(this.boards[ii].categoryId == 0)
          boards.push(this.boards[ii]);
      }
      return boards;
    }
  },
  methods:{
    initialize(){
      // Set params from route
      if (this.$route.params.boards) {
        this.boards = this.$route.params.boards;
      }
      if (this.$route.params.boardCategories) {
        this.boardCategories = this.$route.params.boardCategories;
      }
      if (this.$route.params.recentTopics) {
        this.recentTopics = this.$route.params.recentTopics;
      }

      // Set global store information
      this.$store.state.screenTitle = 'Project B8 Message Boards'
      this.$store.state.screenType = 'boardList'
      this.$store.state.boardId = null
      this.$store.state.boardTitle = null
      this.$store.state.topicId = null
    },
    categoryBoards(categoryId){
      let boards = [];
      for(var ii = 0; ii < this.boards.length; ii++){
        if(this.boards[ii].categoryId == categoryId)
          boards.push(this.boards[ii]);
      }
      return boards;
    },
    hasAnyBoards(categoryId){
      for(var ii = 0; ii < this.boards.length; ii++){
        if(this.boards[ii].categoryId == categoryId)
          return true;
      }
      return false;
    },
    selectBoard(boardId){
      this.$router.push(`/b${boardId}`);
    }
  },
  setup(){    
    return {
    }
  }
}
</script>
