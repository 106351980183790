<template>
  <div v-if="userInfo" class="w-full flex flex-col gap-y-0.5">
    <div v-for="(info, i) in ['userName', 'userId', 'userLevel', 'joined', 'karma', 'signature']" 
      :key="info" :class="`gf-content flex flex-row`">
      <div class="w-32 flex-shrink-0 uppercase">{{info}}</div>
      <div v-if="info == 'signature'" v-html="userInfo[info]" class="flex-grow whitespace-pre" />  
      <div v-else class="flex-grow">{{userInfo[info]}}</div>  
    </div>
    <!--Account Settings-->
    <div v-if="isSelf" class="flex flex-col gap-y-0.5">
      <div class="w-full gf-header font-bold">Update Account Settings</div>
      <div v-if="error" class="gf-notice p-0.5 text-center font-bold">{{error}}</div>
      <!--Signature-->
      <div class="w-full flex flex-row gf-content p-0.5">
        <div class="w-32 flex-shrink-0">Signature</div>
        <div class="flex-grow pr-4">
          <textarea v-model="signature" class="w-full h-20" />
        </div>
      </div>
      <!--Per Page-->
      <div class="w-full flex flex-row gf-content p-0.5">
        <div class="w-32 flex-shrink-0">Messages Per Page</div>
        <div class="flex-grow pr-4">
          <input v-model="mpp" />
        </div>
      </div><!--
      <div class="w-full flex flex-row gf-content p-0.5">
        <div class="w-32 flex-shrink-0">Topics Per Page</div>
        <div class="flex-grow pr-4">
          <input v-model="tpp" />
        </div>
      </div>-->
      <!--Sidebar / Topbar Settings-->    
      <div class="w-full flex flex-row gf-content p-0.5">
        <div class="w-32 flex-shrink-0">Show Sidebar</div>
        <div class="flex-grow pr-4">
          <input type="checkbox" v-model="showSidebar" />
        </div>
      </div> 
      <div class="w-full flex flex-row gf-content p-0.5">
        <div class="w-32 flex-shrink-0">Sticky Header</div>
        <div class="flex-grow pr-4">
          <input type="checkbox" v-model="stickyHeader" />
        </div>
      </div>


      <div class="w-full flex justify-center gf-content">
        <div @click="updateSettings()" class="p-2 gf-button rounded-sm">Update Settings</div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue';

import ControlBar from '@/components/ControlBar.vue'
import Form from '@/components/Form.vue'
import PageBar from '@/components/PageBar.vue'

export default {
  name: 'User',
  components: {
  },
  props: {
    boardId: Number,
    topicId: Number,
    userName: String
  },
  data(){
    return {
      userInfo: []
    }
  },
  async mounted(){
    this.initialize();
  },
  methods:{
    initialize(){      
      // Set params from route
      if (this.$route.params.userInfo) {
        this.userInfo = this.$route.params.userInfo;
      }

      // Set global store information and screen type
      this.$store.state.screenTitle = this.userInfo.userName
      document.title = this.$store.state.screenTitle
      this.$store.state.screenType = 'profile'


      // Check if user viewing own profile
      if(this.$store.state.user.userId == this.userInfo.userId){
        this.isSelf = true;
        this.signature = this.userInfo.signature;
        this.mpp = this.userInfo.messagesPerPage
        this.tpp = this.userInfo.topicsPerPage
        this.showSidebar = this.userInfo.showSidebar == 1
        this.stickyHeader = this.userInfo.stickyHeader == 1
      }
    },
    updateSettings(){
      this.error = '';
      let url = `${this.host}/users/update.php`
      let body = 
      {
        signature: this.signature,
        messagesPerPage: this.mpp,
        topicsPerPage: this.tpp,
        showSidebar: this.showSidebar,
        stickyHeader: this.stickyHeader,
        token: localStorage.getItem('token')
      }

      // Update values for this session
      this.$store.state.messagesPerPage = this.mpp
      this.$store.state.topicsPerPage = this.tpp
      this.$store.state.showSidebar = this.showSidebar
      this.$store.state.stickyHeader = this.stickyHeader


      let request = {
        method: 'POST',
        headers: {'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }
      // Sending the request to the server
      fetch(url, request)
        .then(response => response.json()) // Parsing the JSON response
        .then(data => {
            if (data.success) {
                this.registered = true
                console.log('User updated successfully:', data);
            } else {
                console.log('Update failed:', data.message);
                this.error = data.message;
            }
        })
        .catch(error => {
            console.error('Error updating user:', error);
                this.error = error;
        });
    }
  },
  setup(){
    const isSelf = ref(false)
    const signature = ref('')
    const error = ref('')
    const mpp = ref()
    const tpp = ref()
    const showSidebar = ref()
    const stickyHeader = ref()

    return {
      error,
      isSelf,
      mpp,
      showSidebar,
      signature,
      stickyHeader,
      tpp
    }
  }
}
</script>
