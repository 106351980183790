<template>
    <div class="flex flex-col gap-y-0.5">
      <div class="w-full flex flex-row items-center gf-message-header px-0.5 gap-x-2">
        <span>From</span>
        <div v-if="isMessageDetail">{{message.userName}}</div>
        <div v-else class="gf-link" @click="this.$router.push(`/b${boardId}/t${topicId}/u${message.userName}`)">{{message.userName}}</div>
        <span>|</span>
        <span>Posted {{message.created}}</span>
        <span v-if="!isMessageDetail" >|</span>
        <span v-if="!isMessageDetail" class="gf-link"  @click="this.$router.push(`/b${boardId}/t${topicId}/m${message.messageId}`)">Message Detail</span>
        <span v-if="!isMessageDetail" >|</span>
        <span v-if="!isMessageDetail" >#{{number}}</span>
      </div>
      <div class="w-full gf-content px-0.5" style="white-space: pre-line; font-size: 14px; line-height: 18px;" 
        v-html="`${message.message}${message.signature ? '\n---\n'+message.signature : ''}`" />
    </div>
</template>

<script>
export default {
  name: 'Path',
  props: {
    boardId: Number,
    topicId: Number,
    message: Object,
    number: Number,
    isMessageDetail: Boolean
  },
  methods: {
  }
}
</script>
