<template>
  <div class="gf-sidebar flex flex-row w-full p-1 justify-start font-bold text-sm">
    <div v-for="(option, i) in topBarOptions" :key="option.to" class="flex flex-row">
      <div class="cursor-pointer font-bold"  @click="optionSelected(option)">{{option.label}}</div><span v-if="i < topBarOptions.length - 1" class="mx-2">|</span>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'TopBar',
  props: {
    msg: String
  },
  computed: {
    topBarOptions(){
      let options = [];
      
      // User, login, and register
      if(this.$store.state.isLoggedIn){
        options.push({ label: `${this.$store.state.user.userName} (${this.$store.state.user.userLevel})`, emit: 'profile' });
        options.push({ label: `Messages (2)`, emit: 'pms' });
        options.push({ label: `Notifications`, emit: 'notifications' });
        options.push({ label: `Logout`, emit: 'logout' });
      }else{
        options.push({ label: `Login`, emit: 'login' });
        options.push({ label: `Register`, emit: 'register' });
      }

      return options;
    }
  },
  methods: {
    async optionSelected(option){
      this.$emit(option.emit);
//      await this.$router.push(option.to)
      //this.$router.go(0)
    }
  },
  setup(){
    return {
    }
  }
}
</script>
